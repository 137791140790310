var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-building-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "app-welcome-message" }, [
            _c("h1", [_vm._v("Overzicht van complexen")]),
            _c("p", [
              _vm._v(
                "In dit overzicht worden alle complexen van uw portefeuille getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren."
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "3", sm: "6" } },
            [
              _c("complex-overview-percentage-standard", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "3", sm: "6" } },
            [
              _c("building-overview-energy-labels", {
                attrs: {
                  token: _setup.tokenDelayed,
                  partyType: "Homes",
                  labelSource: "EPOnline",
                },
                scopedSlots: _vm._u([
                  {
                    key: "headerwidget",
                    fn: function () {
                      return [_c("h3", [_vm._v("Verdeling van het label")])]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "3", sm: "6" } },
            [
              _c("complex-overview-gasless", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { md: "3", sm: "6" } },
            [
              _c("complex-overview-co2-norm", {
                attrs: { token: _setup.tokenDelayed },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [
          _c("v-col", [
            _c(
              "article",
              { staticClass: "inner" },
              [
                _c(
                  "v-row",
                  { staticClass: "overview-filters align-items-start" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("filter-set", {
                          attrs: {
                            name: _setup.FilterTokenName.Complex,
                            filters: _setup.filters,
                          },
                          on: {
                            "filter-search": _setup.handleFilterSearch,
                            "filters-changed27": _setup.handleFiltersChanged,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-col",
                      { staticClass: "text-right", attrs: { cols: "auto" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "primary",
                            attrs: {
                              to: { name: "ComplexesAddStartingPoint" },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { dark: "", left: "" } }, [
                              _vm._v("mdi-update"),
                            ]),
                            _vm._v(" Maatregelen genereren "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(_setup.ComplexGrid, { attrs: { token: _setup.token } }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }